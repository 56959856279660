<template>
    <gk-searchbar
        v-model:form="searchForm"
        :config="searchConfig"
        :fold-display-number="3"
        :foldable="true"
        :options="searchOptions"
        @reset="getDataList"
        @search="getDataList"
    />
    <div class="common-list">
        <div class="button-wapper"></div>
        <div class="table-wrapper">
            <el-table :data="dataList" border stripe @selection-change="changeSelectionIds">
                <el-table-column label="ID" prop="id" width="100" />
                <el-table-column label="订单号" prop="order.order_sn" />
                <el-table-column label="订单状态">
                    <template #default="scope">
                        <template v-if="scope.row.status === 0">审核中</template>
                        <template v-if="scope.row.status === 1">已完成</template>
                        <template v-if="scope.row.status === 2">未通过</template>
                    </template>
                </el-table-column>
                <el-table-column label="备注" prop="remark" />
                <el-table-column label="操作" width="150">
                    <template #default="scope">
                        <el-button plain size="mini" type="primary" @click="openDetailDialog(scope.row.id)">
                            详情
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination-wrapper">
            <gk-pagination
                v-model:currentPage="dataPagination.current"
                v-model:pageSize="dataPagination.size"
                :total="dataPagination.total"
                @change="getDataList"
            />
        </div>
    </div>
    <goods-after-sale-detail :id="detailDialog.id" v-model:show="detailDialog.show" />
</template>

<script setup>
import { onMounted, ref } from "vue";
import useCommonList from "../../../../hooks/useCommonList";
import GoodsAfterSaleDetail from "./GoodsAfterSaleDetail.vue";
import { GET_GOODS_AFTER_SALE_LIST } from "@/api/goods";

let { dataPagination, dataList, selectionIds, changeSelectionIds } = useCommonList();

onMounted(() => {
    getDataList();
});

// 搜索项
let searchConfig = ref([{ prop: "status", type: "select", label: "售后状态" }]);

let searchOptions = ref({
    status: [
        { label: "全部", value: 0 },
        { label: "已完成", value: 1 },
        { label: "审核中|未通过", value: 2 }
    ]
});

let searchForm = ref({
    status: 0
});

// 获取数据
let getDataList = () => {
    GET_GOODS_AFTER_SALE_LIST({
        ...searchForm.value,
        page: dataPagination.value.current,
        limit: dataPagination.value.size
    }).then((res) => {
        dataList.value = res.data.data.list;
        dataPagination.value.total = res.data.data.mate.total;
        console.log(res.data.data);
    });
};

// 查看详情
let detailDialog = ref({
    show: false,
    id: null
});

let openDetailDialog = (id) => {
    detailDialog.value = {
        show: true,
        id: id
    };
};
</script>
