<template>
    <el-dialog
        :model-value="show"
        :before-close="onClose"
        :close-on-click-modal="false"
        custom-class="common-edit"
        title="售后详情"
        width="600px"
    >
        <div v-if="detailData.order" class="detail-container">
            <p>订单号：{{ detailData.order.order_sn }}</p>
            <p v-if="detailData.units">
                <span>施工单位：</span>
                <span>{{ detailData.units.name }}</span>
            </p>
            <p>
                <span>安装状态：</span>
                <span v-if="detailData.status === 0">待安装</span>
                <span v-if="detailData.status === 1">进行中</span>
                <span v-if="detailData.status === 2">已安装</span>
            </p>
            <p v-if="detailData.status_at">
                <span>完成时间：</span>
                <span>{{ detailData.status_at }}</span>
            </p>
            <p>备注：{{ detailData.remark }}</p>
            <el-table :data="detailData.poles" border stripe>
                <el-table-column label="设备号" prop="equipment" />
                <el-table-column label="生产序列号" prop="production" />
                <el-table-column label="资产序列号" prop="assets" />
            </el-table>
        </div>
    </el-dialog>
</template>

<script setup>
import { ref, watch } from "vue";
import { GET_GOODS_AFTER_SALE_DETAIL } from "@/api/goods";

let props = defineProps({
    show: {
        type: Boolean,
        default: false
    },
    id: { type: [String, Number] }
});

let emit = defineEmits(["update:show"]);

watch(
    () => props.show,
    (newVal) => {
        if (newVal) {
            getDetail();
        } else {
            detailData.value = {};
        }
    },
    { deep: true }
);

// 获取详情
let detailData = ref({});

let getDetail = () => {
    GET_GOODS_AFTER_SALE_DETAIL({ id: props.id }).then((res) => {
        detailData.value = res.data.data;
        console.log(111, res.data.data);
    });
};

// 关闭
let onClose = () => {
    emit("update:show", false);
};
</script>

<style lang="scss" scoped>
.detail-container {
    p {
        margin-bottom: 20px;
        font-size: 16px;
        color: #333333;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
